import React, { useEffect, useState } from 'react'
import DeviceImgPlaceholder from './../../assets/images/image-placeholder.png'

import DownArrow from './../../assets/images/down-arrow.svg'
function Result(props) {
  const {
    mobileModal,
    DeviceImg,
    modelResult,
    modelCount,
    loaderStatus,
    errorMessage,
    price,
    model,
    description,
    modalHeight,
  } = props

  return (
    <>
      {mobileModal &&
        (modelResult.length > 0 ? (
          <>
            <div className='mo-img-price-block'>
              {modelResult.map((data) => {
                return (
                  <div className=''>
                    <div className='img-wrapper'>
                      <img
                        src={
                          data.image
                            ? process.env.REACT_APP_PUBLIC_URL +
                              'model-images' +
                              data.image
                            : DeviceImg
                        }
                        alt='Device'
                      />
                    </div>
                    <div className='mo-content-block'>
                      {data.price && (
                        <div className='price-wrapper'>
                          <span className='lbl-title'>MSRP</span>
                          <div className='details-text'>${data.price}</div>
                        </div>
                      )}

                      {data.model && (
                        <div className='modal-wrapper'>
                          <span className='lbl-title'>Model Number</span>
                          <div className='details-text'>{data.model}</div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        ) : (
          <div className='mo-img-price-block'>
            <div className=''>
              <div className='img-wrapper'>
                <img src={DeviceImg} alt='Device' />
              </div>
              <div className='mo-content-block'>
                {price && (
                  <div className='price-wrapper'>
                    <span className='lbl-title'>MSRP</span>
                    <div className='details-text'>${price}</div>
                  </div>
                )}

                {model && (
                  <div className='modal-wrapper'>
                    <span className='lbl-title'>Model Number</span>
                    <div className='details-text'>{model}</div>
                  </div>
                )}
                {errorMessage && (
                  <div className='content-wrapper'>
                    <span className='lbl-title'>
                      Combination is not matched
                    </span>
                    <p className='content'>{description}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      {/* /////// */}
      {modelResult.length > 0 && loaderStatus === false ? (
        <>
          <div className='image-price-count'>
            <div className='count'>Selected Models : {modelCount}</div>
            <div className='img-price-block' style={{ height: modalHeight }}>
              {modelResult.map((data) => {
                let dataModel = data.model
                return (
                  <div className=''>
                    <div className='img-wrapper'>
                      <div className='bounce'>
                        <img
                          className='down-animated-arrow'
                          src={DownArrow}
                          alt='Down Arrow'
                        />
                      </div>

                      {/* <div class='lds-ring'>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div> */}
                      <img
                        src={
                          data.image
                            ? process.env.REACT_APP_PUBLIC_URL +
                              'model-images' +
                              data.image
                            : DeviceImgPlaceholder
                        }
                        alt='Device'
                      />
                    </div>
                    {data.price && (
                      <div className='price-wrapper'>
                        <span className='lbl-title'>MSRP</span>
                        <div className='details-text'>${data.price}</div>
                      </div>
                    )}

                    {data.model && (
                      <div className='modal-wrapper'>
                        <span className='lbl-title'>Model Number</span>
                        <div className='details-text'>{data.model}</div>
                      </div>
                    )}
                    {data.description && (
                      <div className='content-wrapper'>
                        <span className='lbl-title'>Description</span>
                        <p className='content'>{data.description}</p>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </>
      ) : (
        <div className='img-price-block' style={{ height: modalHeight }}>
          <div className=''>
            <div className='img-wrapper'>
              {loaderStatus && (
                <div class='lds-ring'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
              <img src={DeviceImg} alt='Device' />
            </div>
            {price && (
              <div className='price-wrapper'>
                <span className='lbl-title'>MSRP</span>
                <div className='details-text'>${price}</div>
              </div>
            )}

            {model && (
              <div className='modal-wrapper'>
                <span className='lbl-title'>Model Number</span>
                <div className='details-text'>{model}</div>
              </div>
            )}
            {errorMessage && (
              <div className='content-wrapper'>
                <span className='lbl-error-title'>
                  Combination is not matched
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {/* <div className='img-price-block'>
        <div className='img-wrapper'>
          <img src={DeviceImg} alt='Device' />
        </div>
        {price && (
          <div className='price-wrapper'>
            <span className='lbl-title'>MSRP</span>
            <div className='details-text'>${price}</div>
          </div>
        )}

        {model && (
          <div className='modal-wrapper'>
            <span className='lbl-title'>Model Number</span>
            <div className='details-text'>{model}</div>
          </div>
        )}
        {description && (
          <div className='content-wrapper'>
            <span className='lbl-title'>Description</span>
            <p className='content'>{description}</p>
          </div>
        )}
      </div> */}
    </>
  )
}

export default Result
