import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import logo from './logo.svg'
import './scss/index.scss'
import './css/index.css'
import Home from './components/Home'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import NotFound from './components/NotFound'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <Home />
        </Route>
        <Route path='' component={NotFound} />
        {/* <Route path='*'>
          <Home />
        </Route> */}
      </Switch>
    </Router>
  )
}

export default App
