import React, { useEffect, useState, useRef } from 'react'
// import RadioOptions from './RadioOption/RadioOptions'
import SelectOption from './SelectOption/SelectOption'
import Result from './Result/Result'
import jsPDF from 'jspdf'
import 'jspdf-autotable'

import DeviceImg from './../assets/images/device.png'
import ResetImg from './../assets/images/arrows-circle.svg'
import DownArrow from './../assets/images/download_arrow.svg'

const ClientMachine = (props) => {
  const { handleBeforeTabClick } = props
  const systemType = 'Client_Machine'
  const API_URL = process.env.REACT_APP_API_URL
  const Token = process.env.REACT_APP_API_ACCESS_TOKEN

  const [mobileModal, setmobileModal] = useState(false)
  const [dropdown, setDropdown] = useState(false)
  const [initialServerData, setInitialServerData] = useState([])
  const [initialSelectedData, setInitialSelectedData] = useState([])
  const [distinctCameraData, setDistinctCameraData] = useState([])

  const [storageRange, setStorageRange] = useState()
  const [formFactor, setFormFactor] = useState()
  const [powerSupply, setPowerSupply] = useState()
  const [monitorSupported, setMonitorSupported] = useState()
  const [category, setCategory] = useState()
  const [cpu, setCPU] = useState()
  const [ram, setRAM] = useState()
  const [stocked, setStocked] = useState()
  const [videoOut, setVideoOut] = useState()
  const [videoType, setVideoType] = useState()

  const [optionStorageRange, setOptionStorageRange] = useState(0)
  const [optionFormFactor, setOptionFormFactor] = useState(0)
  const [optionPowerSupply, setOptionPowerSupply] = useState(0)
  const [optionMonitorSupported, setOptionMonitorSupported] = useState(0)
  const [optionCategory, setOptionCategory] = useState(0)
  const [optionCpu, setOptionCPU] = useState(0)
  const [optionRam, setOptionRAM] = useState(0)
  const [optionStocked, setOptionStocked] = useState(0)
  const [optionVideoOut, setOptionVideoOut] = useState(0)
  const [optionVideoType, setOptionVideoType] = useState(0)

  const [radioToggleStorageRange, setRadioToggleStorageRange] = useState(true)
  const [radioToggleFormFactor, setRadioToggleFormFactor] = useState(true)
  const [radioTogglePowerSupply, setRadioTogglePowerSupply] = useState(true)
  const [radioToggleMonitorSupported, setRadioToggleMonitorSupported] =
    useState(true)
  const [radioToggleCategory, setRadioToggleCategory] = useState(true)
  const [radioToggleCPU, setRadioToggleCPU] = useState(true)
  const [radioToggleRAM, setRadioToggleRAM] = useState(true)
  const [radioToggleStocked, setRadioToggleStocked] = useState(true)
  const [radioToggleVideoOut, setRadioToggleVideoOut] = useState(true)
  const [radioToggleVideoType, setRadioToggleVideoType] = useState(true)

  const [selectedStorageRange, setSelectedStorageRange] = useState([])
  const [selectedFormFactor, setSelectedFormFactor] = useState([])
  const [selectedPowerSupply, setSelectedPowerSupply] = useState([])
  const [selectedMonitorSupported, setSelectedMonitorSupported] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [selectedCpu, setSelectedCPU] = useState([])
  const [selectedRam, setSelectedRAM] = useState([])
  const [selectedStocked, setSelectedStocked] = useState([])
  const [selectedVideoOut, setSelectedVideoOut] = useState([])
  const [selectedVideoType, setSelectedVideoType] = useState([])

  const [resetVal, setResetVal] = useState(1)
  const [pdfErrMessage, setPdfErrMessage] = useState('')
  const [modelResult, setModelResult] = useState([])
  const [loaderStatus, setLoaderStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const refHeight = useRef(null)
  const [height, setHeight] = useState(0)
  const [modelCount, setModelCount] = useState(0)

  useEffect(async () => {
    await getDistinctData()
  }, [
    optionStorageRange,
    optionFormFactor,
    optionMonitorSupported,
    optionPowerSupply,
    optionCpu,
    optionRam,
    optionCategory,
    optionStocked,
    optionVideoType,
    optionVideoOut,
  ])

  const clearRadio = async (serverOption) => {
    switch (serverOption[0]) {
      case 'Storage Range':
        if (optionStorageRange != '') {
          setOptionStorageRange('')
          setStorageRange('')
          setSelectedStorageRange('')
          setRadioToggleStorageRange(true)
          return true
        } else {
          return false
        }
      case 'Form Factor':
        if (optionFormFactor != '') {
          setOptionFormFactor('')
          setFormFactor('')
          setSelectedFormFactor('')
          setRadioToggleFormFactor(true)
          return true
        } else {
          return false
        }

      case 'Monitors Supported':
        if (optionMonitorSupported != '') {
          setOptionMonitorSupported('')
          setMonitorSupported('')
          setSelectedMonitorSupported('')
          setRadioToggleMonitorSupported(true)
          return true
        } else {
          return false
        }
      case 'Dual Reduandant Power Supply':
        if (optionPowerSupply != '') {
          setOptionPowerSupply('')
          setPowerSupply('')
          setSelectedPowerSupply('')
          setRadioTogglePowerSupply(true)
          return true
        } else {
          return false
        }
      case 'CPU':
        if (optionCpu != '') {
          setOptionCPU('')
          setCPU('')
          setSelectedCPU('')
          setRadioToggleCPU(true)
          return true
        } else {
          return false
        }
      case 'RAM':
        if (optionRam != '') {
          setOptionRAM('')
          setRAM('')
          setSelectedRAM('')
          setRadioToggleRAM(true)
          return true
        } else {
          return false
        }
      case 'Category':
        if (optionCategory != '') {
          setOptionCategory('')
          setCategory('')
          setSelectedCategory('')
          setRadioToggleCategory(true)
          return true
        } else {
          return false
        }
      case 'Stocked':
        if (optionStocked != '') {
          setOptionStocked('')
          setStocked('')
          setSelectedStocked('')
          setRadioToggleStocked(true)
          return true
        } else {
          return false
        }
      case 'Video Type':
        if (optionVideoType != '') {
          setOptionVideoType('')
          setVideoType('')
          setSelectedVideoType('')
          setRadioToggleVideoType(true)
          return true
        } else {
          return false
        }
      case 'Video Out':
        if (optionVideoOut != '') {
          setOptionVideoOut('')
          setVideoOut('')
          setSelectedVideoOut('')
          setRadioToggleVideoOut(true)
          return true
        } else {
          return false
        }
      default:
        return true
    }
  }

  const sortAlphaNum = (a, b) => {
    return a.localeCompare(b, 'en', { numeric: true })
  }

  const HandleDropdown = () => {
    if (dropdown === false) {
      setDropdown(true)
    } else {
      setDropdown(false)
    }
  }

  const ChangeOptions = async (e) => {
    handleBeforeTabClick(1)
    const field_name = e.target.name
    const field_value = e.target.value
    let preSelectdData = initialSelectedData
    preSelectdData[field_name] = field_value
    setInitialSelectedData(preSelectdData)
    if (field_name === 'storage_range') {
      setStorageRange(field_value)
      setOptionStorageRange(field_value)

      // await getDistinctData()
    } else if (field_name === 'form_factor') {
      setFormFactor(field_value)
      setOptionFormFactor(field_value)
      // await getDistinctData()
    } else if (field_name === 'monitor_supported') {
      setMonitorSupported(field_value)
      setOptionMonitorSupported(field_value)
      // await getDistinctData()
    } else if (field_name === 'dual_reduandant_power_supply') {
      setPowerSupply(field_value)
      setOptionPowerSupply(field_value)
      // await getDistinctData()
    } else if (field_name === 'CPU') {
      setCPU(field_value)
      setOptionCPU(field_value)
      // await getDistinctData()
    } else if (field_name === 'RAM') {
      setRAM(field_value)
      setOptionRAM(field_value)
      // await getDistinctData()
    } else if (field_name === 'category') {
      setCategory(field_value)
      setOptionCategory(field_value)
      // await getDistinctData()
    } else if (field_name === 'stocked') {
      setStocked(field_value)
      setOptionStocked(field_value)
      // await getDistinctData()
    } else if (field_name === 'video_out') {
      setVideoOut(field_value)
      setOptionVideoOut(field_value)
      // await getDistinctData()
    } else if (field_name === 'video_type') {
      setVideoType(field_value)
      setOptionVideoType(field_value)
      // await getDistinctData()
    }
    // else {
    //   await getTheResult(preSelectdData)
    // }
  }

  const getServerData = async () => {
    try {
      const resultServer = await fetch(
        API_URL + '/server-form/getSelectionControlValueWeb',
        {
          method: 'POST',
          body: JSON.stringify({ server: 'Client_Machine' }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + Token,
          },
        }
      )
      const parseResultData = await resultServer.json()
      const serverResult = await parseResultData.data.Client_Machine
      setInitialServerData(serverResult)
      setDistinctCameraData(serverResult)
      getTheResult({})
    } catch (err) {
      console.log('data error', err)
    }
  }

  const getDistinctData = async () => {
    let formData = []
    const serverOptions = {
      storage_range: optionStorageRange ? optionStorageRange : '',
      form_factor: optionFormFactor ? optionFormFactor : '',
      monitor_supported: optionMonitorSupported ? optionMonitorSupported : '',
      power_supply: optionPowerSupply ? optionPowerSupply : '',
      CPU: optionCpu ? optionCpu : '',
      RAM: optionRam ? optionRam : '',
      category: optionCategory ? optionCategory : '',
      stocked: optionStocked ? optionStocked : '',
      video_out: optionVideoOut ? optionVideoOut : '',
      video_type: optionVideoType ? optionVideoType : '',
    }
    console.log(serverOptions)
    for (let property in serverOptions) {
      let encodedKey = encodeURIComponent(property)
      let encodedValue = encodeURIComponent(serverOptions[property])
      formData.push(encodedKey + '=' + encodedValue)
    }
    formData = formData.join('&')
    const resultCameraOptions = await fetch(
      API_URL + '/server-config/getdistinctvalue-client-machine',
      {
        method: 'POST',
        body: formData,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Token ' + Token,
        },
      }
    )
    const responseDistictData = await resultCameraOptions.json()
    if (responseDistictData.status == 200) {
      const resultServerDistictData = responseDistictData.data.Client_Machine
      console.log(resultServerDistictData)
      await updateInitialSelectedData(resultServerDistictData)
      await getTheResult(serverOptions)
    } else {
      setModelResult([])
      setErrorMessage(true)
    }
  }

  const updateInitialSelectedData = async (distinctServerData) => {
    distinctServerData.map(async (dserver, index) => {
      if (dserver['Storage Range']) {
        setSelectedStorageRange(dserver['Storage Range'])
        if (dserver['Storage Range'].length === 1) {
          setStorageRange(dserver['Storage Range'][0])
        }
      }

      if (dserver['Form Factor']) {
        setSelectedFormFactor(dserver['Form Factor'])
        if (dserver['Form Factor'].length === 1) {
          setFormFactor(dserver['Form Factor'][0])
        }
      }

      if (dserver['Monitors Supported']) {
        setSelectedMonitorSupported(dserver['Monitors Supported'])
        if (dserver['Monitors Supported'].length === 1) {
          setMonitorSupported(dserver['Monitors Supported'][0])
        }
      }

      if (dserver['Dual Reduandant Power Supply']) {
        setSelectedPowerSupply(dserver['Dual Reduandant Power Supply'])
        if (dserver['Dual Reduandant Power Supply'].length === 1) {
          setPowerSupply(dserver['Dual Reduandant Power Supply'][0])
        }
      }

      if (dserver['CPU']) {
        setSelectedCPU(dserver['CPU'])
        if (dserver['CPU'].length === 1) {
          setCPU(dserver['CPU'][0])
        }
      }

      if (dserver['RAM']) {
        setSelectedRAM(dserver['RAM'])
        if (dserver['RAM'].length === 1) {
          setRAM(dserver['RAM'][0])
        }
      }

      if (dserver['Category']) {
        setSelectedCategory(dserver['Category'])
        if (dserver['Category'].length === 1) {
          setCategory(dserver['Category'][0])
        }
      }

      if (dserver['Stocked']) {
        setSelectedStocked(dserver['Stocked'])
        if (dserver['Stocked'].length === 1) {
          setStocked(dserver['Stocked'][0])
        }
      }

      if (dserver['Video Type']) {
        setSelectedVideoType(dserver['Video Type'])
        if (dserver['Video Type'].length === 1) {
          setVideoType(dserver['Video Type'][0])
        }
      }

      if (dserver['Video Out']) {
        setSelectedVideoOut(dserver['Video Out'])
        if (dserver['Video Out'].length === 1) {
          setVideoOut(dserver['Video Out'][0])
        }
      }
    })
  }

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          ref.current &&
          event.target.id != 'pdf_download' &&
          !ref.current.contains(event.target)
        ) {
          setDropdown(false)
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  useEffect(() => {
    setHeight(refHeight.current.clientHeight)
  })

  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  useEffect(async () => {
    await getServerData()
    await getDistinctData()
  }, [resetVal])

  const resetData = async (e) => {
    setOptionStorageRange('')
    setStorageRange('')
    setSelectedStorageRange('')
    setRadioToggleStorageRange(true)

    setOptionFormFactor('')
    setFormFactor('')
    setSelectedFormFactor('')
    setRadioToggleFormFactor(true)

    setOptionMonitorSupported('')
    setMonitorSupported('')
    setSelectedMonitorSupported('')
    setRadioToggleMonitorSupported(true)

    setOptionPowerSupply('')
    setPowerSupply('')
    setSelectedPowerSupply('')
    setRadioTogglePowerSupply(true)

    setOptionCPU('')
    setCPU('')
    setSelectedCPU('')
    setRadioToggleCPU(true)

    setOptionRAM('')
    setRAM('')
    setSelectedRAM('')
    setRadioToggleRAM(true)

    setOptionCategory('')
    setCategory('')
    setSelectedCategory('')
    setRadioToggleCategory(true)

    setOptionStocked('')
    setStocked('')
    setSelectedStocked('')
    setRadioToggleStocked(true)

    setOptionVideoType('')
    setVideoType('')
    setSelectedVideoType('')
    setRadioToggleVideoType(true)

    setOptionVideoOut('')
    setVideoOut('')
    setSelectedVideoOut('')
    setRadioToggleVideoOut(true)

    setInitialServerData([])
    setInitialSelectedData([])
    setDistinctCameraData([])
    handleBeforeTabClick(0)
    setModelResult([])
    let value = resetVal + 1
    setResetVal(value)
  }

  const getTheResult = async (data) => {
    setLoaderStatus(true)
    setPdfErrMessage('')
    setModelResult([])
    let preSelectdData = data
    try {
      let formData = []
      for (let property in preSelectdData) {
        let encodedKey = encodeURIComponent(property)
        let encodedValue = encodeURIComponent(preSelectdData[property])
        formData.push(encodedKey + '=' + encodedValue)
      }
      formData = formData.join('&')

      const fetchModelDetail = await fetch(
        API_URL + '/server-config/getmodelpricedescription-client-machine',
        {
          method: 'POST',
          body: formData,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Token ' + Token,
          },
        }
      )
      let result = await fetchModelDetail.json()
      if (result.data.length > 0) {
        setPdfErrMessage('')
        setModelResult(result.data)
        setModelCount(result.data.length)
        setLoaderStatus(false)
        setErrorMessage(false)
      } else {
        setPdfErrMessage('')
        setModelResult([])
        setLoaderStatus(false)
        setErrorMessage(true)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const downloadPDF = () => {
    if (
      storageRange &&
      formFactor &&
      monitorSupported &&
      powerSupply &&
      cpu &&
      ram &&
      category &&
      stocked &&
      videoType &&
      videoOut
    ) {
    } else {
      // setPdfErrMessage(
      //   'Please select at leat one option from each field the available options'
      // )
      // return false
    }

    if (modelResult) {
      const pdfDoc = new jsPDF()
      // pdfDoc.autoTable({ html: '#my-table' })
      pdfDoc.autoTable({
        head: [
          [
            {
              content: 'Client Machine',
              colSpan: 5,
              styles: { halign: 'center' },
            },
          ],
        ],
      })

      let bodyArray = [
        formFactor ? ['Form Factor', formFactor] : '',
        formFactor ? ['Form Factor', formFactor] : '',
        storageRange ? ['Storage Range', storageRange] : '',
        monitorSupported ? ['Monitors Supported', monitorSupported] : '',
        powerSupply ? ['Dual Reduandant Power Supply', powerSupply] : '',
        cpu ? ['CPU', cpu] : '',
        ram ? ['RAM', ram] : '',
        category ? ['Category', category] : '',
        stocked ? ['Stocked', stocked] : '',
        videoType ? ['Video Type', videoType] : '',
        videoOut ? ['Video Out', videoOut] : '',
      ]
      let newBodyArray = bodyArray.filter((arr) => arr.length > 0)

      pdfDoc.autoTable({ html: '#my-table' })
      pdfDoc.autoTable({
        head: [['Configuration Title', 'Configuration Values']],
        body: newBodyArray,
      })
      if (modelResult) {
        modelResult.forEach((result) => {
          pdfDoc.autoTable({
            head: [
              ['Configuration Result Title', 'Configuration Result Value'],
            ],
            body: [
              ['Model', result.model],
              ['Price', '$' + result.price + '/-'],
              ['Description', result.description],
            ],
          })
        })
      }
      pdfDoc.save('Client_Machine.pdf')
    } else {
      setPdfErrMessage('Please populate result first')
    }
  }

  useEffect(() => {
    getServerData()
  }, [])

  const handleScroll = (fs) => {
    let sy = window.scrollY
    if (sy > fs) {
      setmobileModal(true)
    } else {
      setmobileModal(false)
    }
  }
  useEffect(() => {
    const offsetTop = document.querySelector(
      // '.tab-content-wrapper .content-block'
      '.content-block'
    ).offsetTop
    window.addEventListener('scroll', () => handleScroll(offsetTop), false)
    return function cleanup() {
      window.removeEventListener('scroll', () => handleScroll(), false)
    }
  }, [mobileModal])

  return (
    <>
      <div className='content-block' ref={refHeight}>
        <div className='form-wrapper'>
          {distinctCameraData &&
            distinctCameraData.map((serverOption, index) => {
              // if (Object.keys(serverOption) == 'Number of Cameras') {
              //   return <></>
              // }
              let resetShow = false
              return (
                <div className='form-group' onChange={ChangeOptions}>
                  <label className='lbl-title'>
                    {Object.keys(serverOption)}
                  </label>
                  <div className='custom-radio-wrapper'>
                    {serverOption['Storage Range'] &&
                      serverOption['Storage Range']
                        .sort(sortAlphaNum)
                        .map((storage_range, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedStorageRange.length === 1 &&
                            selectedStorageRange.includes(storage_range)
                          ) {
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedStorageRange.includes(storage_range)) {
                            enabled = 1
                          }
                          if (
                            optionStorageRange ||
                            selectedStorageRange.length > 1
                          ) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`storage_range_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='storage_range'
                                id={`storage_range_${index}`}
                                {...(radioToggleStorageRange
                                  ? { checked: isChecked }
                                  : {})}
                                value={storage_range}
                              />
                              {enabled ? (
                                <label
                                  for={`storage_range_${index}`}
                                  onClick={() => {
                                    setRadioToggleStorageRange(false)
                                  }}
                                >
                                  <span>{storage_range}</span>
                                </label>
                              ) : (
                                <label for={`storage_range_${index}`}>
                                  <span>{storage_range}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['Form Factor'] &&
                      serverOption['Form Factor']
                        .sort(sortAlphaNum)
                        .map((form_factor, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedFormFactor.length === 1 &&
                            selectedFormFactor.includes(form_factor)
                          ) {
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedFormFactor.includes(form_factor)) {
                            enabled = 1
                          }
                          if (
                            optionFormFactor ||
                            selectedFormFactor.length > 1
                          ) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`form_factor_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='form_factor'
                                id={`form_factor_${index}`}
                                {...(radioToggleFormFactor
                                  ? { checked: isChecked }
                                  : {})}
                                value={form_factor}
                              />
                              {enabled ? (
                                <label
                                  for={`form_factor_${index}`}
                                  onClick={() => {
                                    setRadioToggleFormFactor(false)
                                  }}
                                >
                                  <span>{form_factor}</span>
                                </label>
                              ) : (
                                <label for={`form_factor_${index}`}>
                                  <span>{form_factor}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['Monitors Supported'] &&
                      serverOption['Monitors Supported']
                        .sort(sortAlphaNum)
                        .map((monitor_supported, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedMonitorSupported.length === 1 &&
                            selectedMonitorSupported.includes(monitor_supported)
                          ) {
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (
                            selectedMonitorSupported.includes(monitor_supported)
                          ) {
                            enabled = 1
                          }
                          if (
                            optionMonitorSupported ||
                            selectedMonitorSupported.length > 1
                          ) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`monitor_supported_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='monitor_supported'
                                id={`monitor_supported_${index}`}
                                {...(radioToggleMonitorSupported
                                  ? { checked: isChecked }
                                  : {})}
                                value={monitor_supported}
                              />
                              {enabled ? (
                                <label
                                  for={`monitor_supported_${index}`}
                                  onClick={() => {
                                    setRadioToggleMonitorSupported(false)
                                  }}
                                >
                                  <span>{monitor_supported}</span>
                                </label>
                              ) : (
                                <label for={`monitor_supported_${index}`}>
                                  <span>{monitor_supported}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['Dual Reduandant Power Supply'] &&
                      serverOption['Dual Reduandant Power Supply']
                        .sort(sortAlphaNum)
                        .map((dual_reduandant_power_supply, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedPowerSupply.length === 1 &&
                            selectedPowerSupply.includes(
                              dual_reduandant_power_supply
                            )
                          ) {
                            enabled = 1
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (
                            selectedPowerSupply.includes(
                              dual_reduandant_power_supply
                            )
                          ) {
                            enabled = 1
                          }
                          if (
                            optionPowerSupply ||
                            selectedPowerSupply.length > 1
                          ) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`dual_reduandant_power_supply_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='dual_reduandant_power_supply'
                                id={`dual_reduandant_power_supply_${index}`}
                                {...(radioTogglePowerSupply
                                  ? { checked: isChecked }
                                  : {})}
                                value={dual_reduandant_power_supply}
                              />
                              {enabled ? (
                                <label
                                  for={`dual_reduandant_power_supply_${index}`}
                                  onClick={() => {
                                    setRadioTogglePowerSupply(false)
                                  }}
                                >
                                  <span>{dual_reduandant_power_supply}</span>
                                </label>
                              ) : (
                                <label
                                  for={`dual_reduandant_power_supply_${index}`}
                                >
                                  <span>{dual_reduandant_power_supply}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['CPU'] &&
                      serverOption['CPU']
                        .sort(sortAlphaNum)
                        .map((CPU, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedCpu.length === 1 &&
                            selectedCpu.includes(CPU)
                          ) {
                            enabled = 1
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedCpu.includes(CPU)) {
                            enabled = 1
                          }
                          if (optionCpu || selectedCpu.length > 1) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`CPU_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='CPU'
                                id={`CPU_${index}`}
                                {...(radioToggleCPU
                                  ? { checked: isChecked }
                                  : {})}
                                value={CPU}
                              />
                              {enabled ? (
                                <label
                                  for={`CPU_${index}`}
                                  onClick={() => {
                                    setRadioToggleCPU(false)
                                  }}
                                >
                                  <span>{CPU}</span>
                                </label>
                              ) : (
                                <label for={`CPU_${index}`}>
                                  <span>{CPU}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['RAM'] &&
                      serverOption['RAM']
                        .sort(sortAlphaNum)
                        .map((RAM, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedRam.length === 1 &&
                            selectedRam.includes(RAM)
                          ) {
                            enabled = 1
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedRam.includes(RAM)) {
                            enabled = 1
                          }
                          if (optionRam || selectedRam.length > 1) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`RAM_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='RAM'
                                id={`RAM_${index}`}
                                {...(radioToggleRAM
                                  ? { checked: isChecked }
                                  : {})}
                                // checked={ram === RAM ? true : false}
                                value={RAM}
                              />
                              {enabled ? (
                                <label
                                  for={`RAM_${index}`}
                                  onClick={(e) => {
                                    setRadioToggleRAM(false)
                                  }}
                                >
                                  <span>{RAM}</span>
                                </label>
                              ) : (
                                <label for={`RAM_${index}`}>
                                  <span>{RAM}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}

                    {serverOption['Category'] &&
                      serverOption['Category']
                        .sort(sortAlphaNum)
                        .map((category_, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedCategory.length === 1 &&
                            selectedCategory.includes(category_)
                          ) {
                            enabled = 1
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedCategory.includes(category_)) {
                            enabled = 1
                          }
                          if (optionCategory || selectedCategory.length > 1) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`category_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='category'
                                id={`category_${index}`}
                                {...(radioToggleCategory
                                  ? { checked: isChecked }
                                  : {})}
                                value={category_}
                              />
                              {enabled ? (
                                <label
                                  for={`category_${index}`}
                                  onClick={() => {
                                    setRadioToggleCategory(false)
                                  }}
                                >
                                  <span>{category_}</span>
                                </label>
                              ) : (
                                <label for={`category_${index}`}>
                                  <span>{category_}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['Stocked'] &&
                      serverOption['Stocked']
                        .sort(sortAlphaNum)
                        .map((stocked_, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedStocked.length === 1 &&
                            selectedStocked.includes(stocked_)
                          ) {
                            enabled = 1
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedStocked.includes(stocked_)) {
                            enabled = 1
                          }
                          if (optionStocked || selectedStocked.length > 1) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`stocked_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='stocked'
                                id={`stocked_${index}`}
                                {...(radioToggleStocked
                                  ? { checked: isChecked }
                                  : {})}
                                value={stocked_}
                              />
                              {enabled ? (
                                <label
                                  for={`stocked_${index}`}
                                  onClick={() => {
                                    setRadioToggleStocked(false)
                                  }}
                                >
                                  <span>{stocked_}</span>
                                </label>
                              ) : (
                                <label for={`stocked_${index}`}>
                                  <span>{stocked_}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['Video Out'] &&
                      serverOption['Video Out']
                        .sort(sortAlphaNum)
                        .map((video_out, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedVideoOut.length === 1 &&
                            selectedVideoOut.includes(video_out)
                          ) {
                            enabled = 1
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedVideoOut.includes(video_out)) {
                            enabled = 1
                          }
                          if (optionVideoOut || selectedVideoOut.length > 1) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`video_out_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='video_out'
                                id={`video_out_${index}`}
                                {...(radioToggleVideoOut
                                  ? { checked: isChecked }
                                  : {})}
                                value={video_out}
                              />
                              {enabled ? (
                                <label
                                  for={`video_out_${index}`}
                                  onClick={() => {
                                    setRadioToggleVideoOut(false)
                                  }}
                                >
                                  <span>{video_out}</span>
                                </label>
                              ) : (
                                <label for={`video_out_${index}`}>
                                  <span>{video_out}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                    {serverOption['Video Type'] &&
                      serverOption['Video Type']
                        .sort(sortAlphaNum)
                        .map((video_type, index) => {
                          let enabled = 0
                          let isChecked = 0
                          if (
                            selectedVideoType.length === 1 &&
                            selectedVideoType.includes(video_type)
                          ) {
                            enabled = 1
                            isChecked = true
                          } else {
                            isChecked = 0
                          }
                          if (selectedVideoType.includes(video_type)) {
                            enabled = 1
                          }
                          if (optionVideoType || selectedVideoType.length > 1) {
                            resetShow = true
                          }
                          return (
                            <div
                              className={`custom-radio ${
                                enabled ? 'radio-enable' : ''
                              }`}
                              key={`video_type_${index}`}
                            >
                              <input
                                disabled={enabled === 0 ? true : false}
                                type='radio'
                                name='video_type'
                                id={`video_type_${index}`}
                                {...(radioToggleVideoType
                                  ? { checked: isChecked }
                                  : {})}
                                value={video_type}
                              />
                              {enabled ? (
                                <label
                                  for={`video_type_${index}`}
                                  onClick={() => {
                                    setRadioToggleVideoType(false)
                                  }}
                                >
                                  <span>{video_type}</span>
                                </label>
                              ) : (
                                <label for={`video_type_${index}`}>
                                  <span>{video_type}</span>
                                </label>
                              )}
                            </div>
                          )
                        })}
                  </div>
                  {resetShow ? (
                    <button
                      onClick={() => clearRadio(Object.keys(serverOption))}
                    >
                      Clear
                    </button>
                  ) : (
                    <button class='disable-btn'>Clear</button>
                  )}
                </div>
              )
            })}
        </div>
        <div className='reset-download'>
          <div className='reser-wrapper'>
            <a onClick={resetData}>
              <img src={ResetImg} alt='Reset' />
              <span>Reset</span>
            </a>
          </div>
          {
            <span className='download_error'>
              {pdfErrMessage ? pdfErrMessage : ''}
            </span>
          }
          <div className='download-wrapper'>
            <a
              ref={wrapperRef}
              className={`btn download-btn ${dropdown ? 'open' : ''}`}
              onClick={HandleDropdown}
            >
              Download
              <img src={DownArrow} alt='' />
            </a>

            {dropdown ? (
              <ul className='dropdown'>
                <li>
                  <a id='pdf_download' onClick={downloadPDF}>
                    PDF File
                  </a>
                </li>
              </ul>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <Result
        modelResult={modelResult}
        modelCount={modelCount}
        DeviceImg={DeviceImg}
        mobileModal={mobileModal}
        loaderStatus={loaderStatus}
        errorMessage={errorMessage}
        modalHeight={height}
      ></Result>
    </>
  )
}

export default ClientMachine
