import React from 'react'
import ViconLogo from './../../assets/images/vicon-logo.svg'
import HeaderCurve from './../../assets/images/header-curve.svg'

const Header = () => {
  return (
    <div className='header-banner'>
      <div className='container'>
        <img className='logo' src={ViconLogo} alt='VICON LOGO' />

        <h1>Online Server Configuration</h1>
        {/* <p>
          Multaque Rem ut Generatio Recurro typi saepe nisl leo offensam’m diam
          dextram eum eorum te eget .
        </p> */}
      </div>

      <div className='head-curve'>
        <img src={HeaderCurve} alt='' />
      </div>
    </div>
  )
}

export default Header
