import React, { useEffect, useState, useContext } from 'react'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Recording from './Recording'
import RecordingRaid from './RecordingRaid'
import AppServer from './AppServer'
import ClientMachine from './ClientMachine'

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [checkedOption, setCheckedOption] = useState(0)

  const handleBeforeTabClick = (val) => {
    if (val == 0) {
      setCheckedOption(0)
    } else if (val == 1) {
      setCheckedOption(1)
    } else {
      setCheckedOption(0)
    }
  }

  const [data, setData] = useState([
    {
      name: 'Client Machines',
      title: 'Client Machines',
      component: <ClientMachine handleBeforeTabClick={handleBeforeTabClick} />,
    },
    {
      name: 'Standard Recorders',
      title: 'Standard Recorders',
      component: <Recording handleBeforeTabClick={handleBeforeTabClick} />,
    },
    {
      name: 'RAID Recorders',
      title: 'RAID Recorders',
      component: <RecordingRaid handleBeforeTabClick={handleBeforeTabClick} />,
    },
    {
      name: 'App Servers',
      title: 'App Servers',
      component: <AppServer handleBeforeTabClick={handleBeforeTabClick} />,
    },
  ])

  const _tabClick = (clickedIndex) => {
    if (checkedOption === 1) {
      let confirmOpt = window.confirm('Are you sure to switch the tab')
      console.log(confirmOpt)
      if (confirmOpt === true) {
        if (clickedIndex !== currentIndex) {
          setCurrentIndex(clickedIndex)
        }
      } else {
      }
    } else {
      if (clickedIndex !== currentIndex) {
        setCurrentIndex(clickedIndex)
      }
    }
  }

  return (
    <>
      <div className='home-page'>
        <Header />
        <main>
          <div className='container'>
            <div className='tabs'>
              {data.map((tab, index) => {
                let indexVal = index
                return (
                  <div
                    key={`tab_${index}`}
                    className={indexVal === currentIndex ? 'active' : ''}
                    onClick={() => _tabClick(index)}
                  >
                    {tab.name}
                  </div>
                )
              })}
            </div>
            <div className='tab-content-wrapper'>
              {data[currentIndex].component}
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Home
