import React, { useState, useEffect, useRef } from 'react'
import ViconLogo from './../../assets/images/logo-footer.svg'
import FooterCurve from './../../assets/images/footer-curve.svg'
import ScrollTop from './../../assets/images/scroll-down-arrow-white.svg'

const Footer = () => {
  const [showScrollTop, setshowScrollTop] = useState(false)
  const handleScroll = (fs) => {
    let sy = window.scrollY
    if (sy > fs) {
      setshowScrollTop(true)
    } else {
      setshowScrollTop(false)
    }
  }

  useEffect(() => {
    const offsetTop = document.querySelector('.tabs').offsetTop
    window.addEventListener('scroll', () => handleScroll(offsetTop), false)
    return function cleanup() {
      window.removeEventListener('scroll', () => handleScroll(), false)
    }
  }, [showScrollTop])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <footer className='footer-wrap'>
      <div className='foot-curve'>
        <img src={FooterCurve} alt='' />
      </div>
      <div className='foot-logo'>
        <img src={ViconLogo} alt='VICON' />
      </div>

      {showScrollTop && (
        <div className='scroll-to-top'>
          {/* <a href='#'></a> */}
          <button onClick={scrollToTop}>
            <img src={ScrollTop} alt='Scroll Top' />
          </button>
        </div>
      )}
    </footer>
  )
}

export default Footer
